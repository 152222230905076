import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import clsx from 'clsx';
import Img from 'gatsby-image/withIEPolyfill';
import { Grid } from '@material-ui/core';
import { Fade } from 'react-reveal';
import i18n from '../../../../locale';
import SectionWrapper from '../../../../components/SectionWrapper';
import Typography from '../../../../components/Typography';
import FooterItem from './FooterItem';
import Image from './Image';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        logo: imageSharp(fluid: {originalName: {eq: "Fleet-Logo-Text.png"}}) {
          fluid(maxWidth: 412) {
            ...GatsbyImageSharpFluid
          }
        }
        fleet0: imageSharp(fluid: {
          originalName : {eq: "Main-Fleet-1.jpg"}
        }) {
          fluid(maxWidth: 1200, maxHeight: 741, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        fleet1: imageSharp(fluid: {
          originalName : {eq: "Main-Fleet-2.jpg"}
        }) {
          fluid(maxWidth: 1200, maxHeight: 741, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        fleet2: imageSharp(fluid: {
          originalName : {eq: "Main-Fleet-3.jpg"}
        }) {
          fluid(maxWidth: 1200, maxHeight: 741, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        narrow: imageSharp(fluid: {
          originalName : {eq: "Main-Fleet-Narrow.jpg"}
        }) {
          fluid(maxWidth: 479, maxHeight: 199, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ logo, ...data }) => (
      <SectionWrapper className={styles.container} id={i18n('_hash:for-customers')}>
        <Grid item container className={styles.titleContainer}>
          <Typography variant='h3' color='dark' weight='bold'
            align='center'
            className={styles.services}>
            {i18n('Services for your customers')}
          </Typography>
          <Typography variant='subtitle1' color='dark' weight='light'
            className={styles.servicesSubtitle}
            align='center'>
            {i18n('Bring customers closer to your workshop by giving them a useful tool in their daily life.')}
          </Typography>
        </Grid>
        <Grid item container className={styles.productNameContainer}>
          <div className={styles.logoContainer}>
            <Img imgStyle={{ objectFit: 'contain' }} 
              objectFit='contain'
              fluid={logo.fluid}
              alt='OBI+ Fleet'/>
          </div>
        </Grid>
        <Grid item xs={12} md={12} className={clsx(
            styles.imageContainer,
            styles.imageContainerNarrow
          )}>
          <Fade bottom>
            <Img objectFit='contain'
              className={styles.image}
              fluid={data.narrow.fluid}/>
          </Fade>
        </Grid>
        <Grid item container xs={12} md={12}
          alignContent='center'
          justify='center'
          className={clsx(
            styles.imageContainer,
            styles.imageContainerWide
          )}>
          <Image fluid={data.fleet0.fluid}
            delay={250}/>
          <Image fluid={data.fleet1.fluid}
            layout={{ md: 4 }}/>
          <Image fluid={data.fleet2.fluid}
            delay={500}/>
        </Grid>
        <Grid container className={styles.footerContainer}>
          <FooterItem text={i18n('Personalized reports specific to their unique fleet usage')}/>
          <FooterItem text={i18n('An easy to use fleet management dashboard for any fleet size')}/>
          <FooterItem text={i18n('Automatic trip-logging and a simple historical overview')}/>
        </Grid>
      </SectionWrapper>
    )}/>
);